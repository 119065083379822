<template>

	<div class="user">
		<h4>我的游记</h4>
		<div class="towrite" @click="towrite(-1)">
			<img src="../../assets/shot/pen.png" alt="">
			<span >写游记</span>
		</div>
		<div class="tab">
			<div :class="{'active':sign==1}" @click="chose(1)">已发布({{count.count_1}})</div>
			<div :class="{'active':sign==0}" @click="chose(0)">草稿({{count.count_0}})</div>

			<div class="bottom"></div>
		</div>

		<div class="coulist">
			<div class="couitem" v-for="(item,i) in articale.cateList" :key="i">
				<div class="c1">
					<p class="t1" @click="toimginfo(item.id,item.type_id)">{{item.title | wuellipsis(11)}}</p>

					<p class="t3">
						<span>{{item.create_time}}</span>
						<span  @click="del(item.id)"><img src="../../assets/user/del.png" alt="">删除</span>
					</p>
				</div>
				<div class="c2">

					<img :src="item.attachment" alt="">
					<div class="edit" @click="towrite(item.id)">
						<img src="../../assets/user/edit.png" alt="">
						<p v-if="sign==1"  >编辑</p>
						<p v-if="sign==0">继续写</p>
					</div>
					<div class="bg"></div>

				</div>
			</div>
		</div>
		<div class="page" style="margin-top:30px">
			  <el-pagination
				layout="prev, pager, next"
				:total="total"
				@current-change="handleCurrentChange"
				:page-size="pageSize">
				</el-pagination>
		  </div>
	</div>

</template>

<script>
import {userArticleListAPI,userArticleDelAPI} from "@/api/user";
export default {

  data () {
    return {
      	sign: "1",
	  	articale:{},
	 	count:{},
	  	total:1,
		page:1,
		pageSize:6

    }
  },
  created () {
	  this.getlist()
  },
  methods: {
	  chose(n){
		 
			 
		this.sign=n
		this.handleCurrentChange(1)
		// location.reload()
		 this.$forceUpdate();
	  },
	handleCurrentChange(currentPage){
			this.page=currentPage
			this.getlist()
	},
    tourl () {
      this.$router.push({ path: '/user/orderInfo' })
    },
	toimginfo(id,tid){
		if (tid==1) {
			this.$router.push({ path: "/imginfo", query: {id} });
		} else {
			this.$router.push({ path: "/videoinfo", query: {id} });
		}
		
	},
	towrite(id){		
		if(id>0){
			this.$router.push({ path: '/uptrival',query:{id}  })
		}else{
			this.$router.push({ path: '/uptrival' })
		}		
	},
	getlist(){	
		var data={
			page:this.page,
			pageSize:this.pageSize,
			status:this.sign
		}
		userArticleListAPI(data).then(res=>{
			this.articale=res
			this.count=res.count
			this.total=res.rowsCount
		})
	},
	del(id){
		console.log(111)
		userArticleDelAPI({id}).then(res=>{
			this.getlist(this.sign)
			this.$message("删除成功！")
		})
	}
  }
}
</script>

<style lang="scss" scoped="scoped">

	.user{
		padding: 5px 20px 20px;
		background: #ffffff;
		min-height: 500px;
		position: relative;
		.towrite{
			position: absolute;
			top: 20px;
			right:20px;
			cursor: pointer;
			width: 138px;
			height: 45px;
			opacity: 1;
			background: #ff7c00;
			border-radius: 8px;
			line-height: 45px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 16px;
			span{
				color: #fff;
				margin-left: 2px;
			}

		}
		h4{
			font-size: 18px;
			font-weight: 500;
			color: #333333;
			font-weight: 500;
			line-height: 45px;
			text-indent: 10px;
		}

		.tab{
			display: flex;
			position: relative;
			div{
				cursor: pointer;
				width: 100px;
				height: 45px;
				font-size: 16px;
				font-weight: 500;
				line-height: 45px;
				text-align: center;
				color: #666666;
				border-bottom: 2px solid #cccccc;
				position: relative;
				z-index: 2;
			}
			.active{
				border-bottom: 2px solid #FF7C00;
				color: #ff7c00;
			}
			.bottom{
				width: 100%;
				height: 2px;
				border-bottom: 2px solid #cccccc;
				position: absolute;
				top: 43px;
				left: 0;
				z-index: 0;
			}

		}

		.coulist{
			display: flex;
			flex-wrap: wrap;
			.couitem{
				width: 460px;
				height: 130px;
				background: #f9f9f9;
				border-radius: 1px;
				padding: 10px;
				margin-top: 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.c1{
					flex: 1;

					margin-right: 10px;
					.t1{
						font-size: 16px;
						font-weight: 600;
						color: #333333;
						line-height: 30px;
						cursor: pointer;
					}
					.t1:hover{
						color: #ff7c00;
					}
					.t3{
						font-size: 14px;
						font-weight: 400;
						color: #999999;
						line-height: 40px;

							display: flex;
							justify-content: space-between;

						span:nth-child(2){
							cursor: pointer;
							color: #FF6565;
							font-size: 11px;
							vertical-align: middle;
							img{
								vertical-align: middle;
								margin-right: 4px;
							}
						}
					}
				}
				.c2{
					width: 210px;
					height: 100px;
					overflow: hidden;
					position: relative;
					>img{
						width: 210px;
						min-height: 100px;
					}
					.bg{
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: rgba(0,0,0,0.48);
						border-radius: 4px;
					}
					.edit{
						cursor: pointer;
						width: 36px;
						position: absolute;
						z-index: 3;
						top: 26%;
						left: 40%;
						img{
							width: 36px;
							height: 36px;
						}
						p{
							font-size: 11px;
							color: #fff;
							text-align: center;
						}
					}

				}
			}
			.couitem:nth-child(odd){
				margin-right: 30px;
			}

		}
	}

</style>
